.swiper-container {
  width: 100%;
  height: auto; /* Adjust to auto for responsive height */
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Adjust padding for responsiveness */
}

.swiper-slide img {
  width: 100%;
  height: auto;
  object-fit: contain; /* Adjust object-fit property */
}

.carousel-caption {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}
