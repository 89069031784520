.CardItem {
  height: auto;
  width:100%;
}


.CardItem-img {
      /* margin: 0px auto; */
      height: auto;
      width: 100%;
    height: 15vw;
     object-fit: cover;
     object-fit:  fill;
}  
    



/* Tablet styles (landscape and portrait) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .CardItem {
    /* Define specific styles for tablets */
  }

  .CardItem-img {
    /* Define specific styles for images on tablets */
    height: 50vw; /* Adjust as needed for tablets */
  }
}

/* Mobile styles (portrait) */
@media only screen and (max-width: 767px) {
  .CardItem {
    /* Define specific styles for mobile */
  }

  .CardItem-img {
    /* Define specific styles for images on mobile */
    height: 30;
    width: 50%;
    height: 50vw; /* Adjust as needed for mobile */
  }
}


    
  
   
    